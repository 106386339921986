<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Banco">
        <BasicFormToolbar
          @new="openNew" @save="save" @list="openTable"
          :actions="['new','save','list']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <Fieldset :toggleable="true" legend="Banco">
                <div class="p-fluid formgrid grid">
                  <FormInputText wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="entity.name" 
                  :valid="validate.validations.name"/>
                  <FormInputText wrapperClass="field col-12 xl:col-2" label="Nombre Corto" v-model="entity.key_name" 
                  :valid="validate.validations.key_name"/>
                  <FormDropdown wrapperClass="field col-2"  v-model="entity.id_bank" label="Banco"
                  :disabled="entity.id" :valid="validate.validations.id_bank" :options="banks" :optionLabel="'name'" :optionValue="'id'"/>
                  <FormInputText wrapperClass="field col-12 xl:col-2" label="Clabe Interbancaria" v-model="entity.key_banking" 
                  :valid="validate.validations.key_banking"/>
                </div>
              </Fieldset>
              <br>
              <Fieldset :toggleable="true" legend="Registros">
        <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
              </Fieldset>
              <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
// import { Bank } from "../../../models/cxp/Bank";
import { BankAccount } from "../../../models/cxp/BankAccount";
import { Bank } from "../../../models/cxp/Bank";
import { FilterMatchMode } from "primevue/api";
import FormDropdown from '../../../components/general/FormDropdown.vue';
import { HeaderGrid, Rule, validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
//import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import Session from "../../../mixins/sessionMixin";

export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      entities: [],
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      banks: [],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" }),
        new Rule({ name: "key_banking"}),
        new Rule({ name: "id_bank"})
      ],
      validate: {
        valid: false,
        validations: {
          name: null,
          key_name: null,
          key_banking: null,
          id_bank: null
        },
      },
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Nombre Corto", "key_name"),
        new HeaderGrid("Clabe Bancaria", "key_banking"),
        new HeaderGrid("Banco", "bank_name")
      ],
      loading: false,
    };
  },
  components: { Loader,BasicFormToolbar, BasicDatatable, DeleteDialog, FormInputText, FormDropdown},
  created() {
    console.log(this.session);
    this.entity = new BankAccount(this.session);
    this.uploadURL =
      this.$config.api_route +
      "cxp/MeasureUnit/Import/" +
      this.session.empresa +
      "/" +
      this.session.sucursal +
      "/" +
      this.session.usuario;
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
    this.$forceUpdate();
  },
  methods: {
    deleted() {
      this.deleteEntity();
    },
    openNew() {
      this.entity = new BankAccount(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new BankAccount(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new BankAccount(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
        this.banks = await new Bank(this.session).all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
